import '../sass/project.scss';

import jQuery from 'jquery';

(function ($) {
  // Begin jQuery
  $(function () {
    // DOM ready
    /*
            Source: https://codepen.io/waverider/pen/QObjRW
        */
    // If a link has a dropdown, add sub menu toggle.
    $('nav ul li a:not(:only-child)').on('click', function (e) {
      e.preventDefault();
      $('nav ul li a.active').removeClass('active');
      $(this).toggleClass('active');
      $(this).siblings('.nav-dropdown').slideToggle();
      // Close one dropdown when selecting another
      $('.nav-dropdown').not($(this).siblings()).slideUp();
      e.stopPropagation();
    });
    // Clicking away from dropdown will remove the dropdown class
    $('html').on('click', function () {
      $('.nav-dropdown').slideUp();
      $('nav ul li a.active').removeClass('active');
    });
    // Toggle open and close nav styles on click
    $('#nav-toggle').on('click', function () {
      $('nav ul.nav-list').slideToggle();
      this.classList.toggle('active');
    });
    $(window).on('resize', function () {
      $('nav ul.nav-list').css('display', '');
      $('#nav-toggle').removeClass('active');
    });
  }); // end DOM ready
})(jQuery); // end jQuery
